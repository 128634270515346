import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Postadsform from "../components/Postadsform";
import Footer from "../components/Footer";

function Postads() {
  return (
    <>
      <div className='py-5' style={{ background: "#eef2f5" }}>
        <Container>
          <Row className='justify-content-md-center align-items-center'>
            <Col sm={10}>
              <Postadsform />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Postads;
