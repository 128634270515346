import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import congrats from "../images/congrats.png";

function AdSuccess(props) {
  const [adId, setadID] = useState();
  useEffect(() => {
    setadID(props?.adId);
  }, [props]);
  return (
    <>
      <div className='py-5' style={{ background: "#eef2f5" }}>
        <Container>
          <Row className='justify-content-md-center align-items-center'>
            <Col md='6' className='text-center'>
              {" "}
              <img
                alt='Congrats'
                src={congrats}
                style={{ maxWidth: "350px" }}
              />
              <p className='text-success bg-success bg-opacity-10 rounded p-2'>
                Advert created successfully
              </p>
              <a href={`/ad/${adId}`} className='btn btn-primary text-white'>
                <i className='bi bi-eye'></i> View Ad
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AdSuccess;
