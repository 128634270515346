import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "../api/axios";
import * as FaIcons from "react-icons/fa";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

function LoginForm(props) {
  const [showlogin, hidelogin] = useState(true);

  const changeform = (event) => {
    if (showlogin === true) {
      hidelogin(false);
    } else {
      hidelogin(true);
    }
  };
  if (showlogin === true) {
    return <LForm showlogin={changeform} />;
  } else {
    return <FlForm showlogin={changeform} />;
  }
}

function LForm({ showlogin }) {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/dashboard";
  const userRef = useRef();
  const errRef = useRef();

  //Reveal And Hide Password
  const [showpass, setShowpass] = useState("password");

  //Authentication Values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //Error Message And Tags
  const [errMsg, setErrMsg] = useState("");

  //Reveal Password And Hid Password Icons Fucntion
  const changePassIcon = () => {
    if (showpass === "password") {
      setShowpass("text");
    } else {
      setShowpass("password");
    }
  };

  //useEffect
  useEffect(() => {
    userRef.current.focus();
  }, []);

  //Clear Error Message ON Change
  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  //Login Authentication Fucntion
  const Auth = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    try {
      //Axio Post
      const response = await axios.post("/login", data, {
        headers: { "Content-type": "application/json" },
        withCredentials: true,
      });
      const accessToken = response?.data?.accessToken;
      setAuth({ email, accessToken });
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <Form onSubmit={Auth}>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live='assertive'>
          {errMsg}
        </p>
        <Form.Group className='form-floating mb-3' controlId='formBasicEmail'>
          <Form.Control
            type='email'
            value={email}
            ref={userRef}
            name='email'
            placeholder='Enter email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Label>Email address</Form.Label>
        </Form.Group>
        <div className='input-group mb-3'>
          <div className='form-floating'>
            <Form.Control
              type={showpass}
              value={password}
              name='password'
              autoComplete='off'
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Label>Password</Form.Label>
          </div>
          <span className='input-group-text me-0' onClick={changePassIcon}>
            <Showpassword showpass={showpass}></Showpassword>
          </span>
        </div>
        <p className='text-end'>
          <span className='btn-link ms-0' onClick={showlogin}>
            Forgot your password?
          </span>
        </p>
        <div className='d-grid text-center'>
          <Button
            variant='primary'
            size='lg'
            className='light_text mb-3'
            type='submit'>
            Login
          </Button>
          <p>
            Don't have an account?{" "}
            <a className='btn-link text-decoration-none ' href='/signup'>
              Sign Up
            </a>
          </p>
        </div>
      </Form>
    </>
  );
}

//Show Forgot Password Form
function FlForm({ showlogin }) {
  return (
    <Form>
      <br />
      <p className='fs-4'>Forgot your password?</p>
      <p>
        No problem, just type in your email and we will help you recover your
        password ;)
      </p>
      <Form.Group className='form-floating mb-3' controlId='formBasicEmail'>
        <Form.Control type='email' placeholder='Enter email' />
        <Form.Label>Email address</Form.Label>
      </Form.Group>
      <div className='text-end'>
        <p>
          <span className='btn-link' href='#' onClick={showlogin}>
            <FaIcons.FaArrowLeft></FaIcons.FaArrowLeft> Back To Login
          </span>
        </p>
      </div>
      <div className='d-grid'>
        <Button
          variant='primary'
          className='light_text'
          size='lg'
          type='submit'>
          Send Password
        </Button>
      </div>
    </Form>
  );
}

//Reveal And Hide Password
const Showpassword = ({ showpass }) => {
  if (showpass === "password") {
    return <FaIcons.FaEyeSlash></FaIcons.FaEyeSlash>;
  } else {
    return <FaIcons.FaEye></FaIcons.FaEye>;
  }
};

//Export Login
export default LoginForm;
