import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import User from "../components/User";
import Footer from "../components/Footer";

function Dashboard() {
  return (
    <>
      <div className='py-5' style={{ background: "#eef2f5" }}>
        <Container>
          <Row className='justify-content-md-center align-items-center'>
            <Col md='10' className='dashboard'>
              <User />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Dashboard;
