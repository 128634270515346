import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardImg,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Myads() {
  const img_directory = "../../images/ads-images/";
  const [myAds, setMyAds] = useState([]);

  const [show, setShow] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [adId, setAdId] = useState(null);
  const [actionFeed, setActionFeed] = useState(false);

  const confirmClose = () => setShow(false);
  const confirmAction = () => setShow(true);

  const axiosPrivate = useAxiosPrivate();
  const getAllMyAds = () => {
    axiosPrivate
      .get("/get-my-ads")
      .then((response) => {
        setMyAds(response.data);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    getAllMyAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletAd = (adId) => {
    axiosPrivate
      .delete(`/${adId}`)
      .then((response) => {
        setActionFeed(true);
        setTimeout(() => {
          confirmClose();
          getAllMyAds();
          setActionFeed(false);
        }, 3000);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const actionTriggered = (adId, action) => {
    setAdId(adId);
    switch (action) {
      case "Delete":
        setActionType(action);
        confirmAction();
        break;

      default:
        break;
    }
  };

  const actionConfirmation = () => {
    switch (actionType) {
      case "Delete":
        deletAd(adId);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div style={{ background: "white" }} className='py-4 px-2 p-md-5'>
        <Container>
          {myAds.length !== 0 ? (
            <Row className='row-cols-1 row-cols-md-2 g-4'>
              {myAds.map((adData, index) => (
                <Col key={index}>
                  <Card>
                    <Row className='g-0'>
                      <Col md='4'>
                        <CardImg
                          className='featured-image'
                          src={`${img_directory}${
                            adData.images.split(" , ")[0]
                          }`}
                        />
                      </Col>
                      <Col md='8'>
                        <Card.Body>
                          <Card.Title>
                            {adData.title}{" "}
                            <span className='fs-4 fw-bolder text-primary'>
                              £ {adData.price}.00
                            </span>
                          </Card.Title>

                          <div className='d-grid gap-2 d-flex  flex-wrap justify-content-between'>
                            <Link
                              to={`/ad/${adData.id}`}
                              className='btn-sm btn btn-primary text-white'
                              size='sm'>
                              <i className='bi bi-eye'></i> View
                            </Link>
                            <Button
                              variant='outline-primary'
                              size='sm'
                              className='white-hover-text flex-fill'>
                              <i className='bi bi-pencil-square'></i> Edit
                            </Button>
                            <Button
                              variant='outline-danger'
                              size='sm'
                              onClick={() =>
                                actionTriggered(adData.id, "Delete")
                              }
                              className='white-hover-text flex-fill'>
                              <i className='bi bi-trash3-fill'></i> Delete
                            </Button>
                            <Button
                              variant='secondary'
                              size='sm'
                              className='white-hover-text flex-fill'>
                              <i class='bi bi-archive-fill'></i> Archive Ad
                            </Button>
                            <Button
                              variant='success'
                              size='sm'
                              className='flex-fill'>
                              <i className='bi bi-coin'></i> Booster
                            </Button>
                          </div>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Row>
              <Col>
                <div className='p-5 text-center'>
                  <p>You have no ad for the moment!</p>
                  <Link
                    className='btn btn-primary light_text'
                    to='/post-your-ad'>
                    Post A Free Ad
                  </Link>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Modal
        centered
        show={show}
        onHide={confirmClose}
        backdrop='static'
        keyboard={false}>
        <ModalHeader closeButton>
          <ModalTitle>Confirm {actionType} Action</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {!actionFeed ? (
            <p className='text-center'>This action can not be reversed.</p>
          ) : (
            <p className='text-center text-success'>
              <i className='bi bi-check2 border text-success border-2 border-success rounded-circle px-2 py-1 fs-4 bg-success bg-opacity-10'></i>
              <br />
              <br />
              Ad was successfully deleted
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant='secondary' onClick={confirmClose}>
            Close
          </Button>
          <Button variant='primary text-white' onClick={actionConfirmation}>
            {" "}
            Confirm Action
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Myads;
