import { Outlet } from "react-router";
import Navbar from "./Navbar";
import "./Navbar.css";
import "../custom.scss";
import "../App.css";

const Layout = () => {
  return (
    <main className='App'>
      <Navbar />
      <Outlet />
    </main>
  );
};

export default Layout;
