import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Profile from "./Profile";
import Myads from "./Myads";
import SavedAds from "./SavedAds";

function User() {
  const [user, setUser] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const effectRun = useRef(false);
  //UseEffect
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getUser = async () => {
      try {
        const response = await axiosPrivate.get("/mydata", {
          signal: controller.signal,
        });
        isMounted && setUser(response.data?.[0]);
      } catch (error) {
        console.log(error);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    // Check if useEffect has run the first time
    if (effectRun.current) {
      getUser();
    }

    return () => {
      isMounted = false;
      controller.abort();
      effectRun.current = true;
    };
  }, [axiosPrivate, navigate, location]);
  return (
    <article>
      <p className='fs-3'>Hi, {user?.firstName}!</p>
      <br />

      <Tabs
        defaultActiveKey='profile'
        className='border-0'
        id='justify-tab-example'
        justify>
        <Tab eventKey='profile' style={{ height: "auto" }} title='My Profile'>
          <Profile user_data={user} />
        </Tab>
        <Tab eventKey='my_ads' title='My Ads'>
          <Myads />
        </Tab>
        <Tab eventKey='saved_ads' title='Saved Ads'>
          <SavedAds />
        </Tab>
      </Tabs>
    </article>
  );
}

export default User;
