import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FloatingLabel,
  FormCheck,
  FormControl,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Row,
} from "react-bootstrap";
import axios from "../api/axios";

function AdPage() {
  const { id } = useParams();
  const [adData, setAdData] = useState([]);
  const [adImages, setAdImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [showPhone, setShowPhone] = useState(false);
  const [messageBox, setMessageBox] = useState(false);

  const img_directory = "../../images/ads-images/";
  useEffect(() => {
    // Make an Axios request using the id from useParams
    const fetchData = async () => {
      try {
        const response = await axios.get(`/${id}`);
        console.log(response.data);
        setAdData(response.data);
        setAdImages(response.data.images.split(" , "));
      } catch (error) {
        console.error("Error fetching ad data:", error);
      }
    };

    fetchData();
  }, [id]);
  return (
    <>
      <div className='py-5' style={{ background: "#eef2f5" }}>
        <Container>
          <Row className='justify-content-md-center'>
            <Col md='10'>
              <p className='fs-2 fw-bolder'>{adData.title}</p>
            </Col>
            <Col md={7}>
              <div>
                <img
                  className='w-100 rounded display-image'
                  alt={`${adData.title} ${imageIndex}`}
                  src={`${img_directory}${adImages[imageIndex]}`}
                />
              </div>
              <div className='d-flex justify-content-center'>
                {adImages.length > 1 ? (
                  <>
                    {adImages.map((imageName, index) =>
                      imageName !== "" ? (
                        <img
                          key={index}
                          onClick={() => setImageIndex(index)}
                          className={`ys-thumbnail ${
                            imageIndex !== index ? "opacity-50" : ""
                          }`}
                          alt={adData.title + index}
                          src={`${img_directory}${imageName}`}
                        />
                      ) : (
                        ""
                      )
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <Card>
                <CardBody>
                  <p className='fs-3 fw-bolder text-primary'>
                    £ {adData.price}.00
                  </p>
                  <p className='fs-5 mb-0'>
                    <i className='bi bi-geo-alt-fill text-primary'></i>{" "}
                    {adData?.user?.state} / {adData?.city}
                  </p>
                </CardBody>
                <ListGroup className='list-group-flush'>
                  <ListGroupItem className='fw-bolder'>
                    Type: {adData?.category}
                  </ListGroupItem>
                  <ListGroupItem>
                    <p className='mb-0 fw-bolder'>Description:</p>
                    {adData?.description}
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardHeader className='bg-primary text-center'>
                  <p className='fs-6 mb-0 text-white'>Seller:</p>
                  <p className='fs-3 mb-0 text-white'>
                    {adData?.user?.displayName
                      ? adData.user.displayName
                      : adData?.user?.firstName + " " + adData?.user?.lastName}
                  </p>
                </CardHeader>
                <CardBody className='text-center d-grid d-block'>
                  {adData?.user?.phoneNumber ? (
                    !showPhone ? (
                      <Button
                        variant='outline-primary'
                        onClick={() => {
                          setShowPhone(true);
                          console.log(showPhone);
                        }}
                        className='mb-2 white-hover-text'>
                        <i className='bi bi-telephone-fill'></i> See Number
                      </Button>
                    ) : (
                      <a
                        href={`tel:${adData.user.phoneNumber}`}
                        className='btn btn-primary light_text mb-2'>
                        <i className='bi bi-telephone-fill'></i>{" "}
                        {adData.user.phoneNumber}
                      </a>
                    )
                  ) : (
                    ""
                  )}
                  {adData?.user?.whatsAppNumber ? (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`https://api.whatsapp.com/send/?phone=${adData.user.whatsAppNumber}&text=Hello%2C%20I%20am%20interested%20in%20your%20ad%20${adData.title}%22%20on%20yousell.co.uk`}
                      className='mb-2 btn btn-outline-success'>
                      <i className='bi bi-whatsapp'></i> Contact Via WhatsApp
                    </a>
                  ) : (
                    ""
                  )}

                  <Button
                    onClick={() => setMessageBox(true)}
                    variant='primary'
                    className='text-white'>
                    <i className='bi bi-chat-right-text'></i> Send Message to
                    Seller
                  </Button>
                </CardBody>
                <CardFooter className='text-center'>
                  <span className=''>
                    <i className='bi bi-patch-check-fill text-success'></i>
                    Verified
                  </span>
                </CardFooter>
              </Card>
              <Modal
                backdrop='static'
                show={messageBox}
                centered
                onHide={() => setMessageBox(false)}>
                <ModalHeader closeButton>
                  <ModalTitle>Send a message to seller</ModalTitle>
                </ModalHeader>
                <ModalBody className='p-md-4'>
                  <FloatingLabel
                    controlId='Name'
                    label='Your Name'
                    className='mb-3'>
                    <FormControl type='text' placeholder='Your Name' />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId='email'
                    label='Your Email Address'
                    className='mb-3'>
                    <FormControl
                      type='email'
                      placeholder='Your Email Address'
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId='message'
                    label='Your Message'
                    className='mb-3'>
                    <FormControl
                      type='textarea'
                      placeholder='Your Message'
                      style={{ height: "80px" }}
                    />
                  </FloatingLabel>
                  <FormCheck
                    type='checkbox'
                    id='messageCopy'
                    label='
                    Receive a copy of this email'
                    className='mb-3'></FormCheck>
                  <Button variant='primary' className='light_text'>
                    Send Message
                  </Button>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
}

export default AdPage;
