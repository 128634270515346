import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import login_bg from "../images/login_bg.jpg";
import yousellicon from "../images/youselllogo_icon.png";

function Login() {
  return (
    <>
      <div style={{ background: "#eef2f5" }}>
        <Container className=''>
          <Row className='py-5 justify-content-center align-items-center'>
            <Col className='d-none d-md-block p-0' sm={5}>
              <div
                className='rounded-start p-5'
                style={{
                  background: `url(${login_bg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "bottom,50%,50%",
                }}>
                <div
                  className='white_overlay p-5 light_text rounded'
                  style={{
                    minHeight: "426px",
                  }}>
                  <h4 className='text-white'>Welcome to MoBerries!</h4>
                  <p className='text-white'>
                    We are happy to see you again. Log in to check your matches
                    - Your next career opportunity might be just one click away.
                  </p>
                </div>
                <div></div>
              </div>
            </Col>
            <Col
              sm={5}
              className='p-0 bg-white'
              style={{
                minHeight: "522px",
              }}>
              <div className='p-lg-5 p-3'>
                <div className='text-center mb-3'>
                  <img
                    src={yousellicon}
                    style={{ width: "60px" }}
                    alt='Logo'></img>
                  <br />
                  <h5>
                    <strong style={{ color: "#ef2227" }}>YouSell</strong>
                    .co.uk
                  </h5>
                </div>
                <LoginForm></LoginForm>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Login;
