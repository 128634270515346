import React from "react";
import secbg_1 from "../images/home_bg.jpg";
import "../App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Registration from "../components/Registration";
import Footer from "../components/Footer";
import Save_time from "../images/Save_time.svg";
import sell_img from "../images/sell_img.png";
import { Button } from "react-bootstrap";

function Home() {
  return (
    <>
      <div style={{ background: `url(${secbg_1})` }}>
        <Container className='min_height  pt-0 pb-0'>
          <Row className='justify-content-md-center'>
            <Col sm={11}>
              <Row className='justify-content-md-center align-items-center'>
                <Col xs={12} md={7} className='light_text'>
                  <h1>Sell Anything You Want In Minutes </h1>
                  <p>
                    Over 2,000 individuals have uploaded items they want to
                    sell.
                  </p>
                </Col>

                <Col xs={12} sm={5}>
                  <div className='dark_overlay p-5'>
                    <Registration />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className='py-5'>
        <Row className='justify-content-md-center'>
          <Col sm={11} className='text-center m-auto'>
            <h2 className='section-title '>
              <span>You</span> Can<span>Sell</span> Anything
            </h2>
            <p>Strengthen your entrepreneur skills</p>
            <Row>
              <Col sm='4' className='p-4'>
                <img
                  src={Save_time}
                  className='img-fluid'
                  width='150px'
                  alt='savetime'
                />
                <h4>Save Time</h4>
                <p>
                  Be efficient – sell items up to 34% faster by prioritizing
                  relevant buyer through our smart filter.
                </p>
              </Col>
              <Col sm='4' className='p-4'>
                <img
                  src={Save_time}
                  className='img-fluid'
                  width='150px'
                  alt='savetime'
                />
                <h4>Save Time</h4>
                <p>
                  Be efficient – sell items up to 34% faster by prioritizing
                  relevant buyer through our smart filter.
                </p>
              </Col>
              <Col sm='4' className='p-4'>
                <img
                  src={Save_time}
                  className='img-fluid'
                  width='150px'
                  alt='savetime'
                />
                <h4>Save Time</h4>
                <p>
                  Be efficient – sell items up to 34% faster by prioritizing
                  relevant buyer through our smart filter.
                </p>
              </Col>
            </Row>
            <Button className='light_text' size='lg'>
              START SELLING NOW
            </Button>
          </Col>
        </Row>
      </Container>
      <div className='py-5 border-top'>
        <Container>
          <Row className='justify-content-md-center align-items-center'>
            <Col sm={6} className='pe-5'>
              <h2 className='section-title '>
                Selling Made Easy By Yousell.co.uk
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s.
                <br />
                <br />
                When an unknown printer took a galley of type and scrambled it
                to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged.
              </p>
              <Button className='light_text' size='lg'>
                START SELLING NOW
              </Button>
            </Col>
            <Col sm='5' className='p-5'>
              <img src={sell_img} className='img-fluid' alt='' />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Home;
