import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <>
      <footer className='border-top'>
        <Container className='p-3'>
          <Row>
            <Col>
              <p className='text-center'>YouSell.co.uk © 2022</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
