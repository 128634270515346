import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import { Link, Navigate } from "react-router-dom";
import { Sidebardata } from "./Sidebardata";
import "../components/Navbar.css";
import { IconContext } from "react-icons";
import { NavLink } from "react-router-dom";
import logo from "../youselllogo.png";
import useLogout from "../hooks/useLogout";
import useAuth from "../hooks/useAuth";
import { Button } from "react-bootstrap";

export default function Navbar() {
  const auth = useAuth();
  const logout = useLogout();
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const signOut = async () => {
    setClick(false);
    await logout();
    return <Navigate to='/login' />;
  };
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className='navbar'>
          <div className='navbar-container container'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              <img src={logo} className='navbar-logo' alt='YouSell.co.uk' />
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaIcons.FaTimes /> : <FaIcons.FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              {Sidebardata.map((item, index) => {
                return (
                  <li key={index} className='nav-item text-start'>
                    <NavLink
                      to={item.path}
                      className={({ isActive }) =>
                        "nav-links" + (isActive ? " activated" : "")
                      }
                      onClick={closeMobileMenu}>
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
              {auth?.auth?.email ? (
                <>
                  <li>
                    <Button
                      variant='primary'
                      className='text-white'
                      onClick={signOut}>
                      Logout
                    </Button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavLink
                      to='/login'
                      className={"btn btn-outline-primary"}
                      // className={({ isActive }) =>
                      //   +(isActive ? "btn btn-outline-primary" : "")
                      // }
                      onClick={closeMobileMenu}>
                      Login
                    </NavLink>
                  </li>
                </>
              )}
              <li></li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}
