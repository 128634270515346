import React from "react";
import {
  Button,
  Card,
  CardGroup,
  CardImg,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import sample_img from "../images/ad_sample.png";

function SavedAds() {
  return (
    <>
      <div style={{ background: "white" }} className='p-5'>
        <Container>
          <Row>
            <Col className='text-center'>
              <Row>
                {Array.from({ length: 4 }).map((_, idx) => (
                  <Col key={idx} sm={6}>
                    <CardGroup className='m-2 rounded'>
                      <Card>
                        <Row className='align-items-center'>
                          <Col sm='4'>
                            <CardImg className='rounded' src={sample_img} />
                          </Col>
                          <Col sm='8' className='text-start'>
                            <Card.Body>
                              <Card.Title>My First Ad</Card.Title>
                              <Button
                                variant='secondary'
                                className='my-1 me-2 light_text'
                                size='sm'>
                                <FaIcons.FaWhmcs></FaIcons.FaWhmcs> Edit Ad
                              </Button>
                              <Button
                                variant='primary'
                                className='my-1 me-2 light_text'
                                size='sm'>
                                <FaIcons.FaTrash></FaIcons.FaTrash> Delete Ad
                              </Button>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Card>
                    </CardGroup>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <div className='p-5'>
                    <p>You don't have any favorites yet</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SavedAds;
