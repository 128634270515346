import React, { useEffect, useState } from "react";
import {
  Card,
  CardGroup,
  CardImg,
  Col,
  Container,
  FormControl,
  Row,
} from "react-bootstrap";
import Searchform from "../components/Searchform";
import * as FaIcons from "react-icons/fa";
import Footer from "../components/Footer";
import axios from "../api/axios";
import { Link } from "react-router-dom";

function Search() {
  const [adsList, setAdsList] = useState([]);
  const img_directory = "../../images/ads-images/";
  useEffect(() => {
    axios
      .get("/get-all-ads")
      .then((response) => {
        setAdsList(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <>
      <div className='py-5' style={{ background: "#eef2f5" }}>
        <Container>
          <Row className='justify-content-md-center align-items-center'>
            <Col sm='12' className='search'>
              <div className='text-center'>
                <h2>Find A Good Deal</h2>
              </div>
              <Searchform />
            </Col>
          </Row>
          <Row className='py-5'>
            {adsList.map((adData, index) => (
              <Col key={index} className='my-3' sm={2}>
                <CardGroup className='h-100 rounded-top'>
                  <Card className='text-start'>
                    <CardImg
                      className='rounded list-image'
                      src={`${img_directory}${adData.images.split(" , ")[0]}`}
                    />
                    <div className='text-white fs-5 position-absolute top-0 end-0 me-2 mt-2'>
                      {adData.images.split(" , ").length - 1}{" "}
                      <i className='bi bi-camera-fill text-white'></i>
                    </div>
                    <Card.Body className='position-relative'>
                      <Card.Title className='pe-4 fw-bolder'>
                        <Link
                          to={"/ad/" + adData.id}
                          className='stretched-link'></Link>
                        {adData.title}
                      </Card.Title>

                      <FormControl
                        type='checkbox'
                        className='btn-check'
                        name='btnradio'
                        id='btnradio2'
                        autocomplete='off'
                      />
                      <label
                        className='position-absolute light_text me-2 mt-2 top-0 end-0 btn btn-sm btn-outline-primary'
                        for='btnradio2'>
                        <FaIcons.FaHeart></FaIcons.FaHeart>
                      </label>
                      <p className='fs-4 fw-bolder text-primary'>
                        £ {adData.price}
                      </p>
                      <p className='mb-0'>
                        {adData.user.state} / {adData.city}
                      </p>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Search;
