import React, { useEffect, useState } from "react";
import {
  Col,
  FloatingLabel,
  FormControl,
  FormSelect,
  InputGroup,
  Row,
} from "react-bootstrap";

export default function AdsMoreInfo(props) {
  const [isVisible, setIsVisible] = useState(false);
  const year = [];
  const currrentYear = new Date().getFullYear();
  for (let x = 1990; x <= currrentYear; x++) {
    year.push(x);
  }
  const [categoryChoice, setCategoryChoice] = useState([]);
  const [adType, setAdType] = useState(null);
  const [adBrand, setAdBrand] = useState(null);
  const [adModel, setAdModel] = useState(null);
  const [prodYear, setProdYear] = useState(null);
  const [mileage, setMileage] = useState(null);
  const [fuel, setFuel] = useState(null);
  const [gear, setGear] = useState(null);
  const [engineSize, setEngineSize] = useState(null);
  const [vehicleFitness, setVehicleFitness] = useState(null);
  const [vehicleInsurance, setVehicleInsurance] = useState(null);
  const [boatLength, setBoatLength] = useState(null);
  const [navTime, setNavTime] = useState(null);

  const moreadinfo = {};
  const moreAdInfo = () => {
    if (adType) moreadinfo.type = adType;

    if (adBrand) moreadinfo.brand = adBrand;
    if (adModel) moreadinfo.model = adModel;

    if (prodYear) moreadinfo.year = prodYear;

    if (mileage) moreadinfo.mileage = mileage;

    if (fuel) moreadinfo.fuel = fuel;

    if (gear) moreadinfo.gear = gear;

    if (engineSize) moreadinfo.engineSize = engineSize;

    if (vehicleFitness) moreadinfo.vehicleFitness = vehicleFitness;

    if (vehicleInsurance) moreadinfo.vehicleInsurance = vehicleInsurance;

    if (boatLength) moreadinfo.boatLength = boatLength;

    if (navTime) moreadinfo.navTime = navTime;

    return moreadinfo;
  };
  useEffect(() => {
    const moreAdData = moreAdInfo();
    props?.moreAdData(moreAdData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adType,
    adBrand,
    adModel,
    prodYear,
    mileage,
    fuel,
    gear,
    engineSize,
    vehicleFitness,
    vehicleInsurance,
    boatLength,
    navTime,
  ]);

  useEffect(() => {
    setCategoryChoice(props?.moreInfo);
  }, [props]);

  useEffect(() => {
    categoryChoice.includes("Vehicles".toUpperCase()) &&
    !categoryChoice.includes("Bicycles")
      ? setIsVisible(true)
      : categoryChoice.includes("ELECTRONICS".toUpperCase()) &&
        !categoryChoice.includes("Video Games and Consoles")
      ? setIsVisible(true)
      : categoryChoice.includes("Services".toUpperCase()) &&
        !(
          categoryChoice.includes("Shared transport") ||
          categoryChoice.includes("Others")
        )
      ? setIsVisible(true)
      : setIsVisible(false);
  }, [categoryChoice]);

  return (
    <>
      {isVisible ? (
        <Row className='fade-in border bg-dark bg-opacity-10 rounded-3 mx-auto pt-3 mb-3'>
          <p className='fs-5'>Tell us more</p>
          <Col md='12'>
            <p className='p-1 bg-info bg-opacity-10 rounded border border-1 border-info fs-6'>
              The more details, the faster your future buyer will find you
            </p>
          </Col>
          {categoryChoice.includes("Vehicles".toUpperCase()) ? (
            <>
              {categoryChoice.includes("Cars") ||
              categoryChoice.includes("Boats") ? (
                <Col md='4'>
                  <FloatingLabel controlId='type' label='Type' className='mb-3'>
                    <FormControl
                      type='text'
                      placeholder='Type'
                      required
                      onChange={(e) => setAdType(e.target.value)}></FormControl>
                  </FloatingLabel>
                </Col>
              ) : null}
              {!categoryChoice.includes("Boats") ? (
                <Col md='4'>
                  <FloatingLabel
                    controlId='Brand'
                    label='Brand'
                    className='mb-3'>
                    <FormControl
                      type='text'
                      required
                      placeholder='Brand'
                      onChange={(e) =>
                        setAdBrand(e.target.value)
                      }></FormControl>
                  </FloatingLabel>
                </Col>
              ) : null}
              {categoryChoice.includes("Cars") ? (
                <Col md='4'>
                  <FloatingLabel
                    controlId='Model'
                    label='Model'
                    className='mb-3'>
                    <FormControl
                      type='text'
                      required
                      placeholder='Model'
                      onChange={(e) =>
                        setAdModel(e.target.value)
                      }></FormControl>
                  </FloatingLabel>
                </Col>
              ) : null}
              <Col md='4'>
                <FloatingLabel controlId='Year' label='Year' className='mb-3'>
                  <FormSelect
                    placeholder='Please select car production year'
                    required
                    onChange={(e) => setProdYear(e.target.value)}>
                    <option>Please select car production year</option>
                    {year.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </FormSelect>
                </FloatingLabel>
              </Col>
              {!categoryChoice.includes("Boats") ? (
                <Col md='4'>
                  <InputGroup className='mb-3'>
                    <FloatingLabel label='Mileage' controlId='Mileage'>
                      <FormControl
                        type='number'
                        required
                        placeholder='30,000'
                        onChange={(e) =>
                          setMileage(e.target.value)
                        }></FormControl>
                    </FloatingLabel>
                    <InputGroup.Text>km/liter</InputGroup.Text>
                  </InputGroup>
                </Col>
              ) : null}
              {!categoryChoice.includes("Boats") &&
              !categoryChoice.includes("Motorcycles") ? (
                <>
                  <Col md='4'>
                    <FloatingLabel
                      controlId='Fuel'
                      label='Fuel'
                      className='mb-3'>
                      <FormSelect
                        placeholder='Fuel'
                        required
                        onChange={(e) => setFuel(e.target.value)}>
                        <option>Please select car fuel</option>
                        <option> Petrol</option>
                        <option> Diesel</option>
                        <option> LPG</option>
                        <option> Electric</option>
                        <option> Hybrid</option>
                      </FormSelect>
                    </FloatingLabel>
                  </Col>
                  <Col md='4'>
                    <FloatingLabel
                      controlId='Gear'
                      label='Gear'
                      className='mb-3'>
                      <FormSelect
                        placeholder='Gear'
                        required
                        onChange={(e) => setGear(e.target.value)}>
                        <option>Please select vehicle gear</option>
                        <option> Manual</option>
                        <option> Automatic</option>
                        <option> Steptronic</option>
                      </FormSelect>
                    </FloatingLabel>
                  </Col>
                </>
              ) : null}
              {!categoryChoice.includes("Boats") ? (
                <>
                  <Col md='4'>
                    <InputGroup className='mb-3'>
                      <FloatingLabel
                        label='Vehicle Engine Size'
                        controlId='vehicleEngineSize'>
                        <FormControl
                          type='number'
                          required
                          placeholder='30,000'
                          onChange={(e) =>
                            setEngineSize(e.target.value)
                          }></FormControl>
                      </FloatingLabel>
                      <InputGroup.Text>CC</InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col md='4'>
                    <FloatingLabel
                      controlId='vehicleFitness'
                      className='mb-3'
                      label='Vehicle Fitness'>
                      <FormControl
                        type='date'
                        required
                        onChange={(e) => setVehicleFitness(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md='4'>
                    <FloatingLabel
                      controlId='vehicleInsurance'
                      className='mb-3'
                      label='Vehicle Insurance'
                      required
                      onChange={(e) => setVehicleInsurance(e.target.value)}>
                      <FormControl type='date' />
                    </FloatingLabel>
                  </Col>
                </>
              ) : null}
              {categoryChoice.includes("Boats") ? (
                <>
                  <Col md='4'>
                    <InputGroup className='mb-3'>
                      <FloatingLabel label='Boat Length' controlId='boatLength'>
                        <FormControl
                          type='number'
                          placeholder='30,000'
                          required
                          onChange={(e) =>
                            setBoatLength(e.target.value)
                          }></FormControl>
                      </FloatingLabel>
                      <InputGroup.Text>Feet.</InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col md='4'>
                    <InputGroup className='mb-3'>
                      <FloatingLabel
                        label='Navigation time'
                        controlId='navigationTime'>
                        <FormControl
                          type='number'
                          required
                          placeholder='30'
                          onChange={(e) =>
                            setNavTime(e.target.value)
                          }></FormControl>
                      </FloatingLabel>
                      <InputGroup.Text>minutes</InputGroup.Text>
                    </InputGroup>
                  </Col>
                </>
              ) : null}
            </>
          ) : categoryChoice.includes("ELECTRONICS") &&
            !categoryChoice.includes("Video Games and Consoles") ? (
            <>
              <Col md={6}>
                <FloatingLabel controlId='type' label='Type' className='mb-3'>
                  <FormControl
                    type='text'
                    placeholder='Type'
                    required
                    onChange={(e) => setAdType(e.target.value)}></FormControl>
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel controlId='Brand' label='Brand' className='mb-3'>
                  <FormControl
                    type='text'
                    placeholder='Brand'
                    required
                    onChange={(e) => setAdBrand(e.target.value)}></FormControl>
                </FloatingLabel>
              </Col>
            </>
          ) : categoryChoice.includes("Services".toUpperCase()) ? (
            <Col md={6}>
              <FloatingLabel controlId='type' label='Type' className='mb-3'>
                <FormControl
                  type='text'
                  placeholder='Type'
                  required
                  onChange={(e) => setAdType(e.target.value)}></FormControl>
              </FloatingLabel>
            </Col>
          ) : null}
        </Row>
      ) : null}
    </>
  );
}
