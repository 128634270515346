export const Category = [
  {
    category: "VEHICLES",
    subcategories: [
      "Cars",
      "Motorcycles",
      "Vans and Trucks",
      "Bicycles",
      "Boats",
    ],
  },
  {
    category: "VEHICLES EQUIPMENTS",
    subcategories: ["Accessories and Spare parts", "Boat equipments"],
  },
  {
    category: "PROPERTY",
    subcategories: [
      "For Sale",
      "To Rent",
      "Shared accommodations",
      "Holidays & week-ends",
    ],
  },
  {
    category: "Furniture",
    subcategories: [
      "Home Appliances",
      "Home Decor",
      "Garden & Pool",
      "DIY (Do it yourself)",
      "Linen",
      "Tableware",
    ],
  },
  {
    category: "ELECTRONICS",
    subcategories: [
      "Phones and Tablets",
      "Camera/ Video/ Audio",
      "Computers",
      "Video Games and Consoles",
    ],
  },
  {
    category: "FASHION & WELLBEING",
    subcategories: [
      "Woman",
      "Man",
      "Shoes (adult)",
      "Watches & Jeweleries",
      "Accessories & Equipments",
      "Make up & Perfumes",
      "Body care",
    ],
  },
  {
    category: "LEISURE & ENTERTAINMENT",
    subcategories: [
      "Sports and Hobbies",
      "Music",
      "Collector Items",
      "Books",
      "Movies",
      "Wine & Dine",
    ],
  },
  {
    category: "KIDS",
    subcategories: [
      "Baby clothing",
      "Baby accessories",
      "Games / Toys / Books",
      "Shoes (0 - 16 yr old)",
      "Children Clothing 3-16 yr old",
      "Fashion accessories",
      "School equipment",
      "Fashion accessories",
      "Fashion accessories",
    ],
  },
  {
    category: "ANIMALS",
    subcategories: [
      "Sales and adoption",
      "Accessories",
      "Lost and found",
      "Services",
    ],
  },
  {
    category: "PROFESSIONAL EQUIPMENT",
    subcategories: [
      "Handling Equipment",
      "Agricultural Equipment",
      "Building site/ structures",
      "Building tools and equipments",
      "Restaurants - Hotels",
      "Office supplies",
      "Retail outlets",
      "Medical Equipment",
      "Others",
    ],
  },
  {
    category: "SERVICES",
    subcategories: [
      "Home",
      "Kids",
      "Personal assistance",
      "Shared transport",
      "Others",
    ],
  },
];
