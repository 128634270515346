import React, { useState, useEffect } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";

// import axios from "../api/axios";
import axiosPrivate from "../api/axios";
import PasswordUpdate from "./PasswordUpdate";

// const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
const ZIPCODE_REGEX =
  /^([Gg][Ii][Rr]\s*0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s*[0-9][A-Za-z]{2})$/gm;
const PHONE_REGEX =
  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?(\d{4}|\d{3}))?$/;

const WHATSAPP_REGEX = /\+\d{3}[ ]?(\d+(-| )?)+/;

function Profile(props) {
  const [user, setUser] = useState([]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [disableButton, setDisabledButton] = useState(true);

  const stateOptions = ["England", "Northern Ireland", "Scotland", "Wales"];
  const [formMessage, setFormMessage] = useState("");
  const [messageAlert, setMessageAlert] = useState("");

  // Validations
  const [isFirstNameValid, setIsFirstNameValid] = useState(null);
  const [isLastNameValid, setIsLastNameValid] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(null);
  const [isWhatsAppValid, setIsWhatsAppValid] = useState(null);
  const [isZipValid, setIsZipValid] = useState(null);

  useEffect(() => {
    setUser(props);
    if (user?.user_data) {
      setEmail(user?.user_data?.email);
      setFirstName(user?.user_data?.firstName);
      setLastName(user?.user_data?.lastName);
      setGender(user?.user_data?.gender);
      setDisplayName(user?.user_data?.displayName);
      setDateOfBirth(user?.user_data?.DOB);
      setPhoneNumber(user?.user_data?.phoneNumber);
      setWhatsApp(user?.user_data?.whatsAppNumber);
      setState(user?.user_data?.state);
      setCity(user?.user_data?.city);
      setZipCode(user?.user_data?.ZipCode);

      // Check if any of the values are null
      const valuesToCheck = [
        user?.user_data?.email,
        user?.user_data?.firstName,
        user?.user_data?.lastName,
        user?.user_data?.gender,
        user?.user_data?.displayName,
        user?.user_data?.DOB,
        user?.user_data?.phoneNumber,
        user?.user_data?.state,
        user?.user_data?.city,
        user?.user_data?.ZipCode,
      ];

      if (valuesToCheck.some((value) => value === null)) {
        formNotification("Incomplete");
      }
    }
  }, [props, user]);

  const anyChange = (e) => {
    setDisabledButton(false);
    switch (e.target.id) {
      case "firstName":
        setIsFirstNameValid(NAME_REGEX.test(e.target.value));
        setFirstName(e.target.value);
        break;
      case "lastName":
        setIsLastNameValid(NAME_REGEX.test(e.target.value));
        setLastName(e.target.value);
        break;

      case "phoneNumber":
        setIsPhoneValid(PHONE_REGEX.test(e.target.value));
        setPhoneNumber(e.target.value);
        break;

      case "whatsAppNumber":
        setIsWhatsAppValid(WHATSAPP_REGEX.test(e.target.value));
        setWhatsApp(e.target.value);
        break;

      case "zipCode":
        setIsZipValid(ZIPCODE_REGEX.test(e.target.value));
        setZipCode(e.target.value);
        break;
      default:
        break;
    }
  };

  const formNotification = (status) => {
    switch (status) {
      case "Incomplete":
        setFormMessage("Please complete your profile.");
        setMessageAlert("danger");
        break;
      case "firstName":
        setFormMessage("Invalid First Name");
        break;
      default:
        break;
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    const data = {
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      email: email,
      gender: gender,
      DOB: dateOfBirth,
      phoneNumber: phoneNumber,
      whatsAppNumber: whatsApp,
      state: state,
      city: city,
      ZipCode: zipCode,
    };
    try {
      await axiosPrivate.post("/update", data);
      setMessageAlert("success");
      setFormMessage("Update Was Sucessfull");
    } catch (error) {
      if (!error?.response) {
        setFormMessage("No Server Response");
      } else if (error.response?.status === 409) {
        setFormMessage("Email has already been used");
      } else {
        setFormMessage("Update was unsuccessful");
      }
    }
  };

  return (
    <>
      <div className='bg-white p-5'>
        {formMessage || "" ? (
          <p
            className={`bg-${messageAlert} bg-opacity-10 text-${messageAlert} p-2 border border-${messageAlert} rounded-1`}>
            <span className='fw-bolder'>Notice: </span>
            {formMessage}
          </p>
        ) : (
          ""
        )}
        <Row>
          {/* First Name  */}
          <Col md='4'>
            <FloatingLabel
              controlId='firstName'
              label='First Name'
              className='mb-3'>
              <Form.Control
                type='input'
                value={firstName || ""}
                onChange={anyChange}
                isValid={isFirstNameValid === null ? "" : isFirstNameValid}
                isInvalid={isFirstNameValid === null ? "" : !isFirstNameValid}
              />
              {isFirstNameValid === true && (
                <Form.Control.Feedback type='valid'>
                  Looks good!
                </Form.Control.Feedback>
              )}
              {isFirstNameValid === false && (
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid Name, Numbers are also not allowed.
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          {/* Last Name */}
          <Col md='4'>
            <FloatingLabel
              controlId='lastName'
              label='Last Name'
              className='mb-3'>
              <Form.Control
                type='input'
                value={lastName || ""}
                onChange={anyChange}
                isValid={isLastNameValid === null ? "" : isLastNameValid}
                isInvalid={isLastNameValid === null ? "" : !isLastNameValid}
              />
              {isLastNameValid === true && (
                <Form.Control.Feedback type='valid'>
                  Looks good!
                </Form.Control.Feedback>
              )}
              {isLastNameValid === false && (
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid Name, Numbers are also not allowed.
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <FloatingLabel
              className='mb-3'
              controlId='displayName'
              label='Display Name'>
              <Form.Control
                type='input'
                value={displayName || ""}
                onChange={(e) => {
                  setDisplayName(e.target.value);
                  anyChange(e);
                }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <FloatingLabel
              controlId='email'
              label='Email dddress'
              className='mb-3'>
              <Form.Control type='email' value={email || ""} readOnly={true} />
            </FloatingLabel>
          </Col>
          {/* Phone Number  */}
          <Col md='4'>
            <FloatingLabel
              controlId='phoneNumber'
              label='Phone Number'
              className='mb-3'>
              <Form.Control
                type='tel'
                value={phoneNumber || ""}
                onChange={anyChange}
                isValid={isPhoneValid === null ? "" : isPhoneValid}
                isInvalid={isPhoneValid === null ? "" : !isPhoneValid}
              />
              {isPhoneValid === true && (
                <Form.Control.Feedback type='valid'>
                  Looks good!
                </Form.Control.Feedback>
              )}
              {isPhoneValid === false && (
                <Form.Control.Feedback type='invalid'>
                  Invalid Phone Number, format +44123456789
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          {/* WhatsAppINput */}
          <Col md='4'>
            <FloatingLabel
              className='mb-3'
              controlId='whatsAppNumber'
              label='WhatsApp Number'>
              <Form.Control
                type='tel'
                value={whatsApp || ""}
                onChange={anyChange}
                isValid={isWhatsAppValid === null ? "" : isWhatsAppValid}
                isInvalid={isWhatsAppValid === null ? "" : !isWhatsAppValid}
              />
              {isWhatsAppValid === true && (
                <Form.Control.Feedback type='valid'>
                  Looks good!
                </Form.Control.Feedback>
              )}
              {isWhatsAppValid === false && (
                <Form.Control.Feedback type='invalid'>
                  Invalid Phone Number, format +44123456789
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <FloatingLabel controlId='gender' label='Gender' className='mb-3'>
              <Form.Select
                type='select'
                value={gender || "Select One Below"}
                onChange={(e) => setGender(e.target.value)}>
                <option></option>
                <option>Male</option>
                <option>Female</option>
                <option>Non-binary</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <FloatingLabel
              controlId='dateOfBirth'
              className='mb-3'
              label='Date of Birth'>
              <Form.Control
                type='date'
                value={dateOfBirth || ""}
                onMouseUp={anyChange}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <FloatingLabel className='mb-3' label='State' controlId='state'>
              <Form.Select
                type='select'
                value={state || ""}
                onMouseUp={anyChange}
                onChange={(e) => setState(e.target.value)}>
                <option>Please Select One</option>
                {stateOptions.map((stateOtion, index) => (
                  <option key={index}>{stateOtion}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <FloatingLabel className='mb-3' label='City' controlId='city'>
              <Form.Control
                type='input'
                value={city || ""}
                onMouseUp={anyChange}
                onChange={(e) => setCity(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <FloatingLabel
              className='mb-3'
              label='Zip Code'
              controlId='zipCode'>
              <Form.Control
                type='input'
                value={zipCode || ""}
                onChange={anyChange}
                isValid={isZipValid === null ? "" : isZipValid}
                isInvalid={isZipValid === null ? "" : !isZipValid}
              />
              {isZipValid === true && (
                <Form.Control.Feedback type='valid'>
                  Looks good!
                </Form.Control.Feedback>
              )}
              {isZipValid === false && (
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid Zip Code.
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          <Col md='4'>
            <Button
              disabled={
                !(
                  isFirstNameValid &&
                  isLastNameValid &&
                  isPhoneValid &&
                  isWhatsAppValid &&
                  isZipValid
                ) === false || ""
                  ? true
                  : disableButton
              }
              onClick={updateProfile}
              size='lg'
              className='light_text w-100 py-3'
              variant='primary'
              type='submit'>
              Update Profile
            </Button>
          </Col>
        </Row>
      </div>
      <PasswordUpdate email={email}></PasswordUpdate>
    </>
  );
}

export default Profile;
