import React from "react";
import {
  Col,
  FloatingLabel,
  Form,
  FormControl,
  FormSelect,
  InputGroup,
  Row,
} from "react-bootstrap";
import * as FaIcons from "react-icons/fa";

function Searchform() {
  return (
    <>
      <Form
        as={Row}
        className='p-3 pt-4 pb-3 border rounded'
        style={{ background: "white" }}>
        <Col md='3'>
          <FloatingLabel controlId='keyword' label='Keyword' className='mb-3'>
            <FormControl type='text' placeholder='Keyword'></FormControl>
          </FloatingLabel>
        </Col>
        <Col md='3'>
          <FloatingLabel
            controlId='category'
            className='mb-3'
            label='Ad Category'>
            <FormSelect defaultValue='Choose ...'>
              <option>Choose...</option>
              <option> </option>
            </FormSelect>
          </FloatingLabel>
        </Col>

        <Col md='3'>
          <InputGroup className='mb-3'>
            <InputGroup.Text className='ms-0'>
              <FaIcons.FaPoundSign></FaIcons.FaPoundSign>
            </InputGroup.Text>

            <FloatingLabel label='Min Price' controlId='min-price'>
              <FormControl type='number' placeholder='Min Price'></FormControl>
            </FloatingLabel>

            <InputGroup.Text>.00</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md='3'>
          <InputGroup className='mb-3'>
            <InputGroup.Text className='ms-0'>
              <FaIcons.FaPoundSign></FaIcons.FaPoundSign>
            </InputGroup.Text>

            <FloatingLabel label='Max Price' controlId='Max Price'>
              <FormControl type='number' placeholder='Max Price'></FormControl>
            </FloatingLabel>

            <InputGroup.Text>.00</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md='3' className='d-none'>
          <FloatingLabel controlId='region' className='mb-3' label='Region'>
            <FormControl type='text' placeholder='Region'></FormControl>
          </FloatingLabel>
        </Col>
        <Col md='3' className='d-none'>
          <FloatingLabel controlId='city' className='mb-3' label='City'>
            <FormControl type='text' placeholder='City'></FormControl>
          </FloatingLabel>
        </Col>
      </Form>
    </>
  );
}

export default Searchform;
