import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import AdsMoreInfo from "./AdsMoreInfo";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AdSuccess from "./AdSuccess";
import { Category } from "./Category";

function Postadsform() {
  const [category, setCategory] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [adTitle, setAdTitle] = useState("");
  const [adPrice, setAdPrice] = useState("");
  const [adDescription, setAdDescription] = useState("");
  const [adCity, setAdCity] = useState("");
  const [adImages, setAdImages] = useState([]);
  const maxImages = 15;
  const [imageLimit, setImageLimit] = useState(false);
  const [adId, setAdId] = useState("");
  const [adSuccess, setAdSuccess] = useState(false);
  const [moreDataFromChild, setMoreDataFromChild] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorStatus, setErrorStatus] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setMoreInfo(category);
  }, [category]);

  const createAds = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    const adInfo = {
      title: adTitle,
      price: adPrice,
      category: category,
      city: adCity,
      description: adDescription,
      published: e.target.value,
    };

    // Append each property of adInfo to formData
    Object.entries(adInfo).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Object.entries(moreDataFromChild).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Append value(s) of file input
    if (adImages.length > 0) {
      for (let i = 0; i < adImages.length; i++) {
        console.log(adImages[i]);
        formData.append("files", adImages[i]);
      }
    } else {
      setErrorStatus(true);
      setErrorMsg("Image Is Required");
      return;
    }

    try {
      const response = await axiosPrivate.post("/createads", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setTimeout(() => {
        setAdId(response.data.id);
        setAdSuccess(true);
      }, 3000);
    } catch (error) {
      if (error?.response.status === 500) {
        setErrorStatus(true);
        setErrorMsg("No Server Response. Please try submitting again.");
      } else if (error.response?.status === 422) {
        setErrorStatus(true);
        setErrorMsg("Image upload failed, Please try submitting again");
      } else if (error.response?.status === 401) {
        setErrorStatus(true);
        setErrorMsg(
          "Login Token Expired. Please try to login again to create your ad."
        );
      }
    }
  };

  const deleteImages = (imageIndex) => {
    setAdImages(adImages.filter((images, index) => index !== imageIndex));
  };
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 10) {
      setImageLimit(true);
    }
    // Filter files to only include png, jpeg, jpg formats
    const validFiles = selectedFiles.filter((file) =>
      /\.(png|jpe?g)$/i.test(file.name)
    );

    setAdImages(validFiles.slice(0, maxImages));
  };

  const moreInfoFromChild = (data) => {
    setMoreDataFromChild(data);
  };
  return (
    <>
      {!adSuccess ? (
        <div style={{ background: "white" }} className='p-5'>
          <h2>Post a free ad</h2>
          <Form>
            <Row>
              <Col md='6'>
                <FloatingLabel
                  className='mb-3'
                  label='Ad Title'
                  controlId='ad_title'>
                  <FormControl
                    type='text'
                    placeholder='Ad Title'
                    onChange={(e) => setAdTitle(e.target.value)}></FormControl>
                </FloatingLabel>
              </Col>
              <Col md='6'>
                <InputGroup className='mb-3'>
                  <InputGroup.Text className='ms-0'>
                    <FaIcons.FaPoundSign></FaIcons.FaPoundSign>
                  </InputGroup.Text>

                  <FloatingLabel label='What is your price ?' controlId='price'>
                    <FormControl
                      type='number'
                      placeholder='What is your price ?'
                      onChange={(e) =>
                        setAdPrice(e.target.value)
                      }></FormControl>
                  </FloatingLabel>

                  <InputGroup.Text>.00</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md='6'>
                <FloatingLabel
                  className='md-3'
                  label='Ads Category'
                  controlId='adsCategory'>
                  <Form.Select onChange={(e) => setCategory(e.target.value)}>
                    <option>Please select a category.</option>
                    {Category.map((parentCategory, index) => (
                      <React.Fragment key={index}>
                        <option disabled={true}>
                          {parentCategory.category}
                        </option>
                        {parentCategory.subcategories.map(
                          (subcatevalue, sub_index) => (
                            <option
                              value={`${parentCategory.category} / ${subcatevalue}`}
                              key={sub_index}>
                              {subcatevalue}
                            </option>
                          )
                        )}
                      </React.Fragment>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col md='6'>
                <FloatingLabel className='mb-3' label='City' controlId='city'>
                  <Form.Control
                    type='input'
                    onChange={(e) => setAdCity(e.target.value)}
                    placeholder='City'
                  />
                </FloatingLabel>
              </Col>
              <Col md='12'>
                <AdsMoreInfo
                  moreAdData={moreInfoFromChild}
                  moreInfo={moreInfo.split(" / ")}
                />
                <FloatingLabel
                  controlId='adsDescription'
                  className='mb-3'
                  label='Ads Description'>
                  <Form.Control
                    placeholder='Describe precisely your good or service'
                    as='textarea'
                    onChange={(e) => setAdDescription(e.target.value)}
                    style={{ minHeight: "150px" }}></Form.Control>
                </FloatingLabel>

                <p className='fs-5 pt-4'>Add some nice pictures</p>
                <p className='p-1 bg-info bg-opacity-10 rounded border border-1 border-info fs-6'>
                  <span className='fw-bolder'>Information:</span> Put your
                  photos in order, the first one is the most important.
                </p>
                <Form.Group controlId='files' className='mb-3'>
                  <Form.Label className='dropBoxArea bg-secondary bg-opacity-10 border border-3 border-secondary rounded'>
                    Click or drop your photos here!
                  </Form.Label>
                  <Form.Control
                    onChange={handleFileChange}
                    type='file'
                    multiple
                    required
                    accept='image/png, image/jpeg, image/jpg'
                    className='d-none'
                    disabled={imageLimit}
                  />
                  <div className='text-center'>
                    {adImages.map((file, index) => (
                      <div
                        key={index}
                        className='position-relative d-inline-block'>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Preview ${index + 1}`}
                          className='previewImages border'
                        />
                        <Button
                          size='sm'
                          variant='primary'
                          onClick={() => deleteImages(index)}
                          className='position-absolute top-0 end-0 text-white'>
                          <i className='bi bi-trash-fill'></i>
                        </Button>
                      </div>
                    ))}
                  </div>
                  {imageLimit && (
                    <p>Image limit exceeded. Maximum allowed: {maxImages}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {errorStatus ? (
              <div className='bg-danger text-danger bg-opacity-25 p-1 border border-danger rounded-1 mb-3'>
                <span className='fw-bolder text-danger'>Error: </span>
                {errorMsg}
              </div>
            ) : null}
            <Button
              size='lg'
              value={true}
              className='light_text'
              variant='primary'
              type='submit'
              onClick={createAds}>
              Publish Ads
            </Button>{" "}
            <Button
              size='lg'
              value={false}
              variant='outline-secondary'
              type='submit'
              onClick={createAds}>
              Save Ads as Draft
            </Button>
          </Form>
        </div>
      ) : (
        <AdSuccess adId={adId} />
      )}
    </>
  );
}

export default Postadsform;
