import React, { useState, useEffect } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import axios from "../api/axios";

const PWD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

function PasswordUpdate(props) {
  const [user, setUser] = useState([]);
  const [email, setEmail] = useState("");
  const [showPass, setShowpass] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [valideNewPass, setValidNewPass] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validMatch, setValidMatch] = useState(null);
  const [enableShowPass, setEnableShowPass] = useState(true);
  const [passMessage, setPassMessage] = useState("");
  const [passAlert, setPassAlert] = useState("");

  useEffect(() => {
    setUser(props);
    if (user?.email) {
      setEmail(user?.email);
    }
  }, [props, user]);

  const passValidation = (e) => {
    setEnableShowPass(false);
    switch (e.target.id) {
      case "newPassword":
        setValidNewPass(PWD_REGEX.test(newPassword));
        setNewPassword(e.target.value);
        break;
      case "confirmPassword":
        const match = newPassword === e.target.value;
        setValidMatch(match);
        setConfirmPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const passwordUpdate = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: currentPassword,
      newPassword: newPassword,
    };
    try {
      const response = await axios.post("/passwordupdate", data, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setPassAlert("success");
      setPassMessage(response?.data?.message);
    } catch (error) {
      setPassAlert("danger");
      console.log(error?.response);
      if (!error?.response) {
        setPassMessage("No Server Response");
      } else if (error?.response?.status === 401) {
        setPassMessage(error?.response?.data?.message);
      } else {
        setPassMessage("Password update was not successful");
      }
    }
  };

  const revealPassword = () => {
    setShowpass(!showPass);
  };
  return (
    <div className='bg-white p-5 border-top'>
      <Row>
        <p
          className={`bg-${passAlert} bg-opacity-10 text-${passAlert} p-2 border border-${passAlert} rounded-1 ${
            passMessage || "" ? "d-block" : "d-none"
          }`}>
          {passMessage}
        </p>
        <Col md='4'>
          <FloatingLabel
            controlId='currentPassword'
            label='Current Password'
            className='mb-3'>
            <Form.Control
              type={showPass ? "password" : "text"}
              value={currentPassword || ""}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder='Current Password'
            />
          </FloatingLabel>
        </Col>
        <Col md='4'>
          <FloatingLabel
            controlId='newPassword'
            label='New Password'
            className='mb-3'>
            <Form.Control
              type={showPass ? "password" : "text"}
              value={newPassword || ""}
              onChange={passValidation}
              placeholder='New Password'
              isValid={valideNewPass === true ? valideNewPass : ""}
              isInvalid={valideNewPass === false ? !valideNewPass : ""}
            />
            {valideNewPass === true && (
              <Form.Control.Feedback type='valid'>
                Looks good!
              </Form.Control.Feedback>
            )}
            {valideNewPass === false && (
              <Form.Control.Feedback type='invalid'>
                Minimum of 8 characters and must include uppercase and lowercase
                letters, a number.
                <br />
                Allowed special characters:{" "}
                <span className='text-white' aria-label='exclamation mark'>
                  !
                </span>{" "}
                <span className='text-white' aria-label='at symbol'>
                  @
                </span>{" "}
                <span className='text-white' aria-label='hashtag'>
                  #
                </span>{" "}
                <span className='text-white' aria-label='dollar sign'>
                  $
                </span>{" "}
                <span className='text-white' aria-label='percent'>
                  %
                </span>
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </Col>
        <Col md='4'>
          <FloatingLabel
            controlId='confirmPassword'
            label='Confirm New Password'
            className='mb-3'>
            <Form.Control
              type={showPass ? "password" : "text"}
              value={confirmPassword || ""}
              onChange={passValidation}
              placeholder='Current Password'
              isValid={validMatch === null ? "" : validMatch}
              isInvalid={validMatch === null ? "" : !validMatch}
            />
            {validMatch === true && (
              <Form.Control.Feedback type='valid'>
                Valid Match
              </Form.Control.Feedback>
            )}
            {validMatch === false && (
              <Form.Control.Feedback type='invalid'>
                Not a match.
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </Col>
      </Row>
      <Row className='align-items-center'>
        <Col md='4'>
          <Button
            disabled={enableShowPass}
            className='light_text w-100 py-3'
            variant='primary'
            type='submit'
            onClick={passwordUpdate}>
            Change Password
          </Button>
        </Col>
        <Col md='4'>
          <Button
            variant='link'
            className='secondary-text'
            disabled={enableShowPass}
            onClick={revealPassword}>
            {showPass ? (
              <>
                <FaIcons.FaEye /> Show Password
              </>
            ) : (
              <>
                <FaIcons.FaEyeSlash />
                Hide Password
              </>
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default PasswordUpdate;
