import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

export const Sidebardata = [
  {
    title: "Home",
    path: "/",
    icons: <AiIcons.AiFillHome />,
    cName: "nav-text",
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    icons: <AiIcons.AiFillDashboard />,
    cName: "nav-text",
  },
  {
    title: "Search",
    path: "/search",
    icons: <FaIcons.FaSearch />,
    cName: "nav-text",
  },
  {
    title: "Create New Ads",
    path: "/post-your-ad",
    icons: <AiIcons.AiFillDashboard />,
    cName: "nav-text",
  },
];
