import React, { useState, useRef, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as FaIcons from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
const PWD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
const REGISTER_URL = "/register";

function Registration() {
  //References
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [validFName, setValidFName] = useState(false);
  const [fNameFocus, setFNameFocus] = useState(false);

  const [lastName, setLastName] = useState("");
  const [validLName, setValidLName] = useState(false);
  const [lNameFocus, setLNameFocus] = useState(false);

  //Email States
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  //Password State
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  //Match Password
  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  //Error States
  const [errMessage, setErrMessage] = useState("");
  const [success, setSuccess] = useState(false);

  //UseEffect Hook
  useEffect(() => {
    userRef.current.focus();
  }, []);

  //FirstName Validation
  useEffect(() => {
    const result = NAME_REGEX.test(firstName);
    setValidFName(result);
  }, [firstName]);

  //FirstName Validation
  useEffect(() => {
    const result = NAME_REGEX.test(lastName);
    setValidLName(result);
  }, [lastName]);

  //Email Validation
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  //Password Validation
  useEffect(() => {
    const result = PWD_REGEX.test(password);
    setValidPassword(result);
    const match = password === matchPassword;
    setValidMatch(match);
  }, [password, matchPassword]);

  useEffect(() => {
    setErrMessage("");
  }, [email, password, matchPassword]);
  const [showpass, setShowpass] = useState("password");

  const changePassIcon = () => {
    if (showpass === "password") {
      setShowpass("text");
    } else {
      setShowpass("password");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    };
    const v1 = EMAIL_REGEX.test(email);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2) {
      setErrMessage("Invalid Entry");
      return;
    }

    try {
      await axios.post(REGISTER_URL, data, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setSuccess(true);
      //clear state and controlled inputs
      setEmail("");
      setPassword("");
      setMatchPassword("");
    } catch (err) {
      if (!err?.response) {
        setErrMessage("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMessage("Email has already been used");
      } else {
        setErrMessage("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  useEffect(() => {
    if (success) {
      navigate("/login", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <p
          ref={errRef}
          className={errMessage ? "errmsg" : "offscreen"}
          aria-live='assertive'>
          {errMessage}
        </p>
        <Row>
          <Form.Group
            as={Col}
            className='form-floating mb-3'
            controlId='firstName'>
            <Form.Control
              required
              ref={userRef}
              type='text'
              name='firstName'
              placeholder='First Name'
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              aria-invalid={validFName ? "false" : "true"}
              aria-describedby='namedes'
              onFocus={() => setFNameFocus(true)}
              onBlur={() => setFNameFocus(false)}
            />
            <Form.Label className='ms-2'>
              First Name
              <FontAwesomeIcon
                icon={faCheck}
                className={validFName ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validFName || !firstName ? "hide" : "invalid"}
              />
            </Form.Label>
          </Form.Group>
          <Form.Group
            as={Col}
            className='form-floating mb-3'
            controlId='lastName'>
            <Form.Control
              required
              type='text'
              name='lastName'
              placeholder='Last Name'
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              aria-invalid={validLName ? "false" : "true"}
              aria-describedby='namedes'
              onFocus={() => setLNameFocus(true)}
              onBlur={() => setLNameFocus(false)}
            />
            <Form.Label className='ms-2'>
              Last Name
              <FontAwesomeIcon
                icon={faCheck}
                className={validLName ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validLName || !lastName ? "hide" : "invalid"}
              />
            </Form.Label>
          </Form.Group>
          <p
            id='namedes'
            className={
              (fNameFocus && firstName && !validFName) ||
              (lNameFocus && lastName && !validLName)
                ? "instructions"
                : "offscreen"
            }>
            <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            Must begin with a letter.
            <br />
            Numbers are also not allowed.
            <br />
            Not all languages are supported, please use english letters if you
            see error.
            <br />
            Letters, commas, hyphens allowed.
          </p>
        </Row>
        <div className='mb-3'>
          <Form.Group as={Col} className='form-floating' controlId='email'>
            <Form.Control
              required
              type='email'
              name='email'
              placeholder='Enter email'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby='emailinfo'
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
            />
            <Form.Label className='ms-1'>
              Email address
              <FontAwesomeIcon
                icon={faCheck}
                className={validEmail ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validEmail || !email ? "hide" : "invalid"}
              />
            </Form.Label>
          </Form.Group>
          <p
            id='emailinfo'
            className={
              emailFocus && email && !validEmail ? "instructions" : "offscreen"
            }>
            <FontAwesomeIcon icon={faInfoCircle} />
            Invalid Email
            <br />
            Please use a valid email in this format jane@yousell.com
          </p>
        </div>
        <div className='mb-3'>
          <div className='input-group'>
            <div className='form-floating'>
              <Form.Control
                type={showpass}
                name='password'
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                aria-invalid={validPassword ? "false" : "true"}
                aria-describedby='passwordinfo'
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
              />
              <Form.Label>
                Password
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validPassword ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validPassword || !password ? "hide" : "invalid"}
                />
              </Form.Label>
            </div>
            <span className='input-group-text me-0' onClick={changePassIcon}>
              <Showpassword showpass={showpass}></Showpassword>
            </span>
          </div>
          <p
            id='passwordinfo'
            className={
              passwordFocus && password && !validPassword
                ? "instructions"
                : "offscreen"
            }>
            <FontAwesomeIcon icon={faInfoCircle} />
            Minimum of 8 characters.
            <br />
            Must include uppercase and lowercase letters, a number.
            <br />
            Allowed special characters:{" "}
            <span className='text-white' aria-label='exclamation mark'>
              !
            </span>{" "}
            <span className='text-white' aria-label='at symbol'>
              @
            </span>{" "}
            <span className='text-white' aria-label='hashtag'>
              #
            </span>{" "}
            <span className='text-white' aria-label='dollar sign'>
              $
            </span>{" "}
            <span className='text-white' aria-label='percent'>
              %
            </span>
          </p>
        </div>
        <div className='mb-3'>
          <div className='input-group'>
            <div className='form-floating'>
              <Form.Control
                type='password'
                id='matchpassword'
                value={matchPassword}
                name='matchPassword'
                placeholder='Confirm Password'
                onChange={(e) => setMatchPassword(e.target.value)}
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby='confirmnote'
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <Form.Label>
                Confirm Password
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validMatch ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validMatch || !matchPassword ? "hide" : "invalid"}
                />
              </Form.Label>
            </div>
          </div>
          <p
            id='confirmnote'
            className={
              matchFocus && !validMatch ? "instructions" : "offscreen"
            }>
            <FontAwesomeIcon icon={faInfoCircle} />
            Must match the first password input field.
          </p>
        </div>
        <div className='d-grid'>
          <Button
            variant='primary'
            className='text-white'
            size='lg'
            type='submit'
            disabled={
              !validEmail ||
              !validFName ||
              !validMatch ||
              !validLName ||
              !validPassword
                ? true
                : false
            }>
            REGISTER NOW
          </Button>
          <p className='text-center my-4'>
            Already have an account?{" "}
            <a href='/login' className='text-decoration-none'>
              Sign in here
            </a>
          </p>
        </div>
      </Form>
    </>
  );
}

const Showpassword = ({ showpass }) => {
  if (showpass === "password") {
    return <FaIcons.FaEyeSlash></FaIcons.FaEyeSlash>;
  } else {
    return <FaIcons.FaEye></FaIcons.FaEye>;
  }
};

export default Registration;
