import React from "react";
import ReactDOM from "react-dom/client";
import "./components/Navbar.css";
import "./App.css";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// import React from "react";
// import { createRoot } from "react-dom/client";
// import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import axios from "axios";
// import { AuthProvider } from "./context/AuthProvider";

// axios.defaults.withCredentials = true;

// const AppLayout = () => (
//   <>
//     <AuthProvider>
//       <Navbar />
//       <Outlet />
//     </AuthProvider>
//   </>
// );

// const router = createBrowserRouter([
//   {
//     element: <AppLayout />,
//     children: [
//       {
//         path: "/",
//         element: <Home />,
//       },
//       {
//         path: "dashboard",
//         element: <Dashboard />,
//       },
//       {
//         path: "login",
//         element: <Login />,
//       },
//       {
//         path: "search",
//         element: <Search />,
//       },
//       {
//         path: "postads",
//         element: <Postads />,
//       },
//       {
//         path: "signup",
//         element: <Signup />,
//       },
//     ],
//   },
// ]);

// createRoot(document.getElementById("root")).render(
//   <RouterProvider router={router} />
// );
