import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import Registration from "../components/Registration";
import login_bg from "../images/login_bg.jpg";
import yousellicon from "../images/youselllogo_icon.png";

function Signup() {
  return (
    <>
      <div style={{ background: "#eef2f5" }}>
        <Container>
          <Row className='justify-content-md-center pt-5 pb-5'>
            <Col
              sm={10}
              className='p-0 rounded border'
              style={{ background: "white" }}>
              <Row className='align-items-center'>
                <Col sm={6}>
                  <div
                    className='rounded-start p-5'
                    style={{
                      background: `url(${login_bg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "bottom,50%,50%",
                    }}>
                    <div className='bg-white background_blur light_text rounded-4 text-white p-lg-5 bg-opacity-25'>
                      <p className='fs-5 mb-0 fw-bolder'>
                        Welcome to YouSell.co.Uk!
                      </p>
                      <p className='fs-6 mb-4'>
                        Register now - your next transaction is just one click
                        away!
                      </p>
                      <p className='fs-5 mb-0 fw-bolder'>ClockSave Time</p>
                      <p className='fs-6 mb-4'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <p className='fs-5 mb-0 fw-bolder'>Get Feedback</p>
                      <p className='fs-6 mb-4'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <p className='fs-5 mb-0 fw-bolder'>Free of Charge</p>
                      <p className='fs-6 mb-4'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <p className='fs-5 mb-0 fw-bolder'>Free of Charge</p>
                      <p className='fs-6 mb-4'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </div>
                    <div></div>
                  </div>
                </Col>
                <Col sm={6} className='p-5 py-3'>
                  <div className='text-center mb-3'>
                    <img
                      src={yousellicon}
                      style={{ width: "60px" }}
                      alt='Logo'></img>
                    <br />
                    <h5>
                      <strong style={{ color: "#ef2227" }}>YouSell</strong>
                      .co.uk
                    </h5>
                  </div>
                  <Registration></Registration>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
