import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Postads from "./pages/Postads";
import Search from "./pages/Search";
import Signup from "./pages/Signup";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth";
import Addnew from "./pages/Addnew";
import AdPage from "./pages/AdPage";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* Public Routes */}
        <Route path='login' element={<Login />} />
        <Route path='/' element={<Home />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/search' element={<Search />} />
        <Route path='/ad/:id' element={<AdPage />} />

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>
        </Route>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path='/post-your-ad' element={<Postads />} />
          </Route>
        </Route>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path='/add-new' element={<Addnew />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
